<template>
  <section class="newuser">
    <Header />
    <div class="container">
      <Setup :first="firstName" :last="lastName" :email="email" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./Header";
import Setup from "./Setup";
export default {
  components: {
    Header,
    Setup
  },
  metaInfo: {
    title: "New User | WOW"
  },
  computed: {
    ...mapGetters(["getUser"]),
    fillDetails() {
      if (this.$route.fullPath.includes("/my-account/create")) {
        return true;
      } else {
        return false;
      }
    },
    firstName() {
      if (this.fillDetails && this.getUser.username) {
        return this.getUser.username.split(" ")[0];
      }
      return "";
    },
    lastName() {
      if (this.fillDetails && this.getUser.username) {
        return this.getUser.username.split(" ")[1];
      }
      return "";
    },
    email() {
      if (this.fillDetails && this.getUser.email) {
        return this.getUser.email;
      }
      return "";
    }
  }
};
</script>

<style scoped lang="scss">
</style>