<template>
  <section class="newuser-header">
    <div class="container">
      <div class="flex__between">
        <router-link :to="isAdmin ? '/admin/users' : '/'" class="breadcrumb">
          <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />
          {{ isAdmin ? 'Back to Users' : 'Home Page' }}
        </router-link>
        <router-link to="/contact-us" class="btn btn__sm btn__green mt-15">Contact</router-link>
      </div>
      <h1 class="text__center padding__sm">New User</h1>
      <p
        class="header--message"
      >If you already have an account with WOW, please click the "Contact" button at the top right to let us know some details about your account. That should allow us to make corrections and reactivate your account.</p>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    isAdmin() {
      return this.$route.fullPath.includes("/admin");
    }
  }
};
</script>

<style scoped lang="scss">
.newuser-header__grid {
  @include grid($cols: 1fr, $col-gap: 25px, $row-gap: 25px);
}
@media (min-width: $sm) {
  .newuser-header__grid {
    grid-template-columns: 3fr 1fr;
  }
}
</style>