<template>
  <section class="newuser-setup margin__md">
    <div class="newuser-setup__grid">
      <section class="tile">
        <h2>Account Setup</h2>
        <p>Phone number is not required but can be helpful if there is a problem with an order.</p>
        <div class="newuser-setup--form">
          <label :class="[errors.first ? 'form--error' : null]">
            First Name*
            <input
              type="text"
              v-model="form.name.first"
              placeholder="first name"
              :disabled="!isAdmin"
            />
          </label>
          <form-error :message="errors.first" />
          <label :class="[errors.last ? 'form--error' : null]">
            Last Name*
            <input
              type="text"
              v-model="form.name.last"
              placeholder="last name"
              :disabled="!isAdmin"
            />
          </label>
          <form-error :message="errors.last" />
          <label :class="[errors.phone ? 'form--error' : null]">
            Phone Number
            <input
              type="tel"
              v-model="form.phone"
              placeholder="your phone"
              v-mask="'(###) ###-####'"
            />
          </label>
          <form-error :message="errors.phone" />
          <label :class="[errors.email ? 'form--error' : null]">
            Email
            <input type="email" v-model="form.email" placeholder="email" :disabled="!isAdmin" />
          </label>
          <form-error :message="errors.email" />
          <span>Email may not be changed as it is associated with your Microsoft sign-in</span>
        </div>
      </section>
      <section class="tile">
        <h2>Delegated Users</h2>
        <div class="newuser-adtl--form">
          <div class="newuser-delegates">
            <p>Select users who will be able to view your orders, use your award balance, and bill orders to you. Only select people that you are ok with using your marketing funds or submitting orders on your behalf.</p>
            <div class="search--bar margin__sm">
              <input type="text" v-model="search" placeholder="name" />
              <div class="btn__search">
                <font-awesome-icon icon="search" size="lg" />
              </div>
            </div>
            <simplebar class="delegates--overflow" data-simplebar-auto-hide="false">
              <p
                v-if="filteredUsers.length <= 0"
                class="text__center text__bold padding__sm"
              >No users found by your search</p>
              <ul class="delegates--list">
                <li v-for="person in filteredUsers" :key="person._id">
                  <label>
                    <input type="checkbox" v-model="form.delegatedUsers" :value="person._id" />
                    <p>
                      <span>{{ person.displayName }}</span>
                      <br />
                      <span class="text__sm">({{ person.email }})</span>
                    </p>
                  </label>
                </li>
              </ul>
            </simplebar>
          </div>
        </div>
      </section>
    </div>
    <div class="newuser-setup--submit margin__md">
      <p
        class="text__center"
      >Your account will be created with the above information setup. Be advised there is additional setup that must be done by the WOW team in order to give you deeper access to the features of this site.</p>
      <p
        v-if="!isValidated"
        class="form-error--message"
      >Please correct the errors above and submit again.</p>
      <button v-if="!isAdmin" class="btn btn__green text__md" @click="submit">Set It Up!</button>
      <button v-else class="btn btn__green text__md" @click="adminSubmit">Set It Up!</button>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    first: {
      type: String,
      default: ""
    },
    last: {
      type: String,
      default: ""
    },
    email: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      search: "",
      isValidated: true,
      adminAccess: ["super-admin"],
      form: {
        name: {
          first: this.first,
          last: this.last
        },
        phone: "",
        email: this.email,
        delegatedUsers: []
      },
      errors: {
        first: null,
        last: null,
        phone: null,
        email: null
      },
      users: null
    };
  },
  computed: {
    ...mapGetters(["getUserDetails"]),
    isAdmin() {
      return this.$route.fullPath.includes("/admin");
    },
    filteredUsers() {
      if (!this.users) return [];
      return this.users.filter(item => {
        return (
          item.displayName.toLowerCase().includes(this.search.toLowerCase()) ||
          item.displayName.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    }
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startCreateSelf",
      "startCreateUser",
      "loadDelegates"
    ]),
    async submit() {
      this.validateForm();
      if (this.isValidated) {
        this.startSetIsSubmitting({ bool: true, heading: "Creating" });
        try {
          await this.startCreateSelf(this.form);
          this.$router.go(-1);
        } catch (err) {
          console.log(err);
        }
        this.startSetIsSubmitting({ bool: false });
      }
    },
    async adminSubmit() {
      if (!this.adminAccess.includes(this.getUserDetails.permission)) return;
      this.validateForm();
      if (this.isValidated) {
        this.startSetIsSubmitting({ bool: true, heading: "Creating" });
        try {
          await this.startCreateUser(this.form);
          this.$router.push(`/admin/users/`);
        } catch (err) {
          console.log(err);
        }
        this.startSetIsSubmitting({ bool: false });
      }
    },
    emailTest(email) {
      const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      const reFBHL = /@fbhl.com/ || /@mollydeanteam.com/;
      return re.test(email) && reFBHL.test(email);
    },
    validateForm() {
      this.isValidated = true;
      this.errors.first =
        this.form.name.first.length <= 0 ? "Please enter a first name" : null;
      this.errors.last =
        this.form.name.last.length <= 0 ? "Please enter a last name" : null;
      if (this.form.phone.length > 0) {
        this.errors.phone =
          this.form.phone.length < 14
            ? "Please enter a complete phone number"
            : null;
      } else {
        this.errors.phone = null;
      }
      this.errors.email = !this.emailTest(this.form.email)
        ? "Please enter a valid fbhl address"
        : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    }
  },
  async mounted() {
    this.startSetIsSubmitting({ bool: true, heading: "Getting Users" });
    try {
      this.users = await this.loadDelegates();
      this.startSetIsSubmitting({ bool: false });
    } catch (err) {
      this.startSetIsSubmitting({ bool: false });
    }
  }
};
</script>

<style scoped lang="scss">
.newuser-setup__grid {
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 50px);
}
.newuser-setup--form {
  @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 15px);
  font-weight: bold;
}
.delegates--overflow {
  max-height: 400px;
  width: 100%;
}
.delegates--list {
  @include grid($cols: 1fr, $row-gap: 10px, $col-gap: 10px, $align: center);
}
.delegates--list li label {
  @include grid($cols: 1fr 9fr, $col-gap: 5px, $align: center);
}
.newuser-setup--submit {
  @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 15px, $align: center);
  button {
    width: fit-content;
    margin: auto;
  }
}
@media (min-width: $sm) {
  .newuser-setup--form label {
    @include grid($cols: 1fr 3fr, $col-gap: 15px, $align: center);
  }
  .delegates--list {
    grid-template-columns: 1fr 1fr;
  }
  .delegates--list li label {
    grid-template-columns: 1fr 7fr;
  }
}
@media (min-width: $lg) {
  .newuser-setup__grid {
    grid-template-columns: 1fr 1fr;
  }
  .delegates--list {
    grid-template-columns: 1fr;
  }
  .delegates--overflow {
    max-height: 200px;
  }
}
@media (min-width: $xl) {
  .delegates--list {
    grid-template-columns: 1fr 1fr;
  }
}
</style>